import { EnvironmentConfig, EnvironmentConfigService } from "../global/environment-config";
import { IApi, ApiResponse, ListRequest, ApiFailureResponse } from "../interfaces/iApi";
import { GetToken } from "./authentication.service";

export class Api<T> implements IApi<T> {
	constructor(entitySlug: string, key: keyof EnvironmentConfig = 'applicationApiPath') {
		this.entitySlug = entitySlug;
		this.apiHost = EnvironmentConfigService.getInstance().get(key);
		this.authKey = GetToken()?.accessToken;
	}
	private apiHost: string;
	private entitySlug: string;
	private authKey: string;

	private serialize = (obj) => {
		var str = [];
		for (var p in obj)
			if (obj.hasOwnProperty(p)) {
				str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
			}
		return str.join("&");
	}

	/** example: POST https://example/slug/{path} */
	post = async <D>(data: D, path: string): Promise<ApiResponse<T>> => {
		const response = await fetch(this.apiHost + this.entitySlug + path, {
			method: 'POST',
			headers: {
				"Accept": 'application/json',
				"Content-Type": 'application/json',
				"Authorization": this.authKey
			},
			body: data ? JSON.stringify(data) : JSON.stringify({})
		});
		const responseBody = await response.json();
		if (response.status >= 400 && response.status < 600)
			throw new Error((responseBody as ApiFailureResponse).errorMessage);
		return (responseBody as ApiResponse<T>).data;
	};

	/** example: GET https://example/slug/{path}  */
	get = async (path: string): Promise<ApiResponse<T>> => {
		const response = await fetch(this.apiHost + this.entitySlug + path, {
			method: 'GET',
			headers: {
				"Accept": 'application/json',
				"Content-Type": 'application/json',
				"Authorization": this.authKey
			}
		});
		const responseBody = await response.json();
		if (response.status >= 400 && response.status < 600)
			throw new Error((responseBody as ApiFailureResponse).errorMessage);
		return responseBody;
	}

	/** example: GET https://example/slug/1 */
	getOne = async (id: string): Promise<ApiResponse<T>> => {
		const request = await fetch(this.apiHost + this.entitySlug + '/' + id, {
			method: 'GET',
			headers: {
				"Accept": 'application/json',
				"Content-Type": 'application/json',
				"Authorization": this.authKey
			}
		});
		const response = await request.json();
		return response;
	};

	/** example: GET https://example/slugs */
	getList = async (listRequest: ListRequest): Promise<ApiResponse<T[]>> => {
		const request = await fetch(this.apiHost + this.entitySlug + `?${this.serialize(listRequest)}`, {
			method: 'GET',
			headers: {
				"Accept": 'application/json',
				"Content-Type": 'application/json',
				"Authorization": this.authKey
			}
		});
		const response = await request.json();
		return response;
	};

	/** example: PUT https://example/slug */
	create = async (body: Omit<T, 'id' | 'created' | 'updated'>): Promise<ApiResponse<T>> => {
		const request = await fetch(this.apiHost + this.entitySlug, {
			method: 'PUT',
			headers: {
				"Accept": 'application/json',
				"Content-Type": 'application/json',
				"Authorization": this.authKey
			},
			body: JSON.stringify(body)
		});
		const response = await request.json();
		return response;
	};

	/** example: PATCH https://example/slug/1 */
	update = async (body: Omit<Partial<T>, 'id' | 'created' | 'updated'>, id: string): Promise<ApiResponse<T>> => {
		const request = await fetch(this.apiHost + this.entitySlug + '/' + id, {
			method: 'PATCH',
			headers: {
				"Accept": 'application/json',
				"Content-Type": 'application/json',
				"Authorization": this.authKey
			},
			body: JSON.stringify(body)
		});
		const response = await request.json();
		return response;
	};

	/** example: DELETE https://example/slug/1 */
	delete = async (id: string): Promise<void> => {
		const request = await fetch(this.apiHost + this.entitySlug + '/' + id, {
			method: 'DELETE',
			headers: {
				"Authorization": this.authKey
			}
		});
		const response = await request.json();
		return response;
	};
};