import { IApi } from "../interfaces/iApi";
import { IAuthentication, LoginCredentials, LoginResponse, SignUpDetails, UserAuthentication } from "../interfaces/iAuthentication";
import { Router } from '../global/router';
import RoundZeroState from '../services/data.store';
import { JwtPayload, jwtDecode } from "jwt-decode";
export class AuthenticationService<T> implements IAuthentication<LoginResponse> {
    private apiService: IApi<T>;
    constructor(type: { new(slug, key): IApi<T>; }, slug: string = '') {
        this.apiService = new type(slug, 'userApiPath');
    }

    async signUp(details: SignUpDetails): Promise<LoginResponse> {
        return this.apiService.post<SignUpDetails>(details, '/signup') as Promise<LoginResponse>;
    }

    async login(loginRequest: LoginCredentials): Promise<LoginResponse> {
        return this.apiService.post<LoginCredentials>(loginRequest, '/login') as Promise<LoginResponse>;
    }

    logout() {
        RoundZeroState.state.dispose();
        localStorage.removeItem('token');
        Router!.push('/');
    }
}

export function GetToken(): UserAuthentication {
    let token = localStorage.getItem('token');
    if (!token || token.length == 0) return null;
    let jwt: JwtPayload = null;
    try { jwt = jwtDecode(token); }
    catch { jwt = null; }
    return { ...jwt, accessToken: token } as UserAuthentication;
}