
export enum UploadFileState {
    Uploading = "Uploading..",
    Completed = "Done",
    Queued = "Queued",
    UploadFinished = "Processing..",
    Error = "Error"
}

export type UploadFile = {
    name: string;
    size: number;
    type: 'application/pdf' | 'application/msword' | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' | 'text/plain';
    state: UploadFileState;
    raw: File;
}

export type UploadedFile = {
    url: string;
    expiration: string;
    generatedFileName: string;
    fileName: string;
    upload: UploadFile;
}

export type UploadedFileStatus = {
    jobId?: string;
    applicantId?: string;
    url: string;
    file: File;
    fileId: string;
    state: UploadFileState;
    lastAction?: Date;
}

export interface IFiles {
    put(url: string, file: File): Promise<Response>;
}