
import { IFiles } from '../interfaces/iFiles';

//const delay = ms => new Promise(res => setTimeout(res, ms));

export class FileService implements IFiles {
    async put(url: string, file: File): Promise<Response> {

        // await delay(1000);
        // return { status: 200 } as Response;

        return fetch(url, {
            body: file,
            mode: "cors",
            method: "PUT",
            // optional headers
            // headers: { "Content-Type": "text/plain" },
        });
    }
}